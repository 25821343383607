img {
    display: inline !important;
}
.ql-formats:last-child > button {
    padding: 0 !important;
}

.main-container {
	font-family: 'Lato';
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
	min-width: 795px;
	max-width: 795px;
}
.ck-editor__editable {
	min-height: 100px;
}