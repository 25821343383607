
.previous.disabled,
.next.disabled {
  display: none;
}
.disabled {
  opacity: 0.2;
  cursor: not-allowed !important;
}

.content {
  max-width: 100%;
  overflow: auto;
  position: relative;
  font-size: 11px;
  margin-top: 50px;
  max-height: 1000px;
  // transform: rotateX(180deg);
  overflow-x: auto;
  // table {
  //   transform: rotateX(180deg);
  // }
  th {
    background: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  }
  .header-column {
    text-align: left;
    font-weight: bold;
    color: white;
    font-size: var(--chakra-fontSizes-base);
    padding: 0.625rem;
    border-bottom: 0.0625rem solid rgb(222, 226, 230);
    border-right: 0.0625rem solid rgb(222, 226, 230);
    overflow: visible;
    min-width: 100px;
    width: 100px;
    transition: padding 100ms ease-in-out 0s;
    background-color: var(--chakra-colors-blue-500);
    &:first-child {
      min-width: 20px;
      width: 20px;
    }
  }
  .header-column > div {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .body-column {
    cursor: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 0;
    display: table-cell;
    opacity: 1;
    transition: padding 100ms ease-in-out 0s;
    min-width: 100px;
    width: 100px;
    background: rgb(255, 255, 255);
    border-right: 0.0625rem solid rgb(222, 226, 230);
    border-top: 0.0625rem solid rgb(222, 226, 230);
    padding: 0.625rem;
    &:first-child {
      min-width: 20px;
      width: 20px;
    }
  }
  
  .css-1 > td > p{
    text-align: center;
    color: #4299e1;
  }
  .css-0 > td > a > p {
    color: #4299e1;
    text-decoration: underline;
  }
  
  .css-0 > .body-column {
    background: rgb(229, 231, 235);
  }
  
  .css-1qh3va {
    color: blue;
    width: 15px;
    height: 15px;
  }
}
