.fc-event-title {
  white-space: normal;
}
.fc-daygrid-day-events {
  padding-left: 4px;
  padding-right: 4px;
}
.fc .fc-daygrid-day-number {
  padding-bottom: 0;
}

.fc-event-content {
  padding: 4px;
  padding-left: 6px;
  width: 100%;
  color: white;
  font-size: var(--chakra-fontSizes-base);
}

.fc .fc-button {
  font-size: var(--chakra-fontSizes-base);
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 4px;
}
.fc .fc-daygrid-day-top {
  font-size: var(--chakra-fontSizes-base);
}
.fc-col-header-cell-cushion {
  font-size: var(--chakra-fontSizes-base);
}
.fc-toolbar-title {
  font-size: var(--chakra-fontSizes-xl) !important;
  font-weight: var(--chakra-fontWeights-bold);
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 4px;
}
.fc .fc-scroller-liquid-absolute {
  overflow: auto !important;
}
.fc-daygrid-body .fc-daygrid-body-unbalanced {
  width: 100%;
}
/* .fc-event{
  width: 100px !important;
} */
@media screen and (max-width: 991px) {
  .fc .fc-day-disabled,
  .fc .fc-day-past {
    display: none;
  }

  .my-calendar-class tr {
    display: flex;
    flex-direction: column;
  }
  .fc-daygrid-body tbody tr {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .fc .fc-daygrid-day-number {
    width: 40px;
    height: 41px;
    position: absolute;
  }

  .fc .fc-day-label {
    display: block;
  }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 20px;
  }

  .fc .fc-daygrid-day-frame {
    display: flex;
    min-height: 40px;
  }

  .fc .fc-daygrid-day-frame > .fc-daygrid-day-top {
    flex: 0 0 40px;
    width: auto;
    position: relative;
  }

  .fc .fc-daygrid-day-frame > .fc-daygrid-day-events {
    flex: 1 0 0%;
    width: 100%;
    max-width: 100%;
    min-width: 145px;
  }

  .fc-daygrid-day-events {
    padding-left: 6px;
    padding-top: 4px;
  }

  .fc-theme-standard th {
    display: none;
  }

  .fc .fc-scrollgrid-section > td {
    border: 0 none;
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border-bottom: 0 none;
  }

  .fc-scrollgrid-sync-table {
    border-bottom: 1px solid var(--chakra-colors-gray-800) !important;
  }
}

@media screen and (max-width: 767px) {
  .fc .fc-button {
    width: 30px;
    padding: 5px;
    border-top: 0;
    border-bottom: 0;
    font-size: var(--chakra-fontSizes-base);
  }

  .fc .fc-button .fc-icon {
    font-size: var(--chakra-fontSizes-base);
  }
  .fc .fc-button {
    width: auto;
  }
  .fc-toolbar-title {
    font-size: var(--chakra-fontSizes-base) !important;
    font-weight: bold;
  }
}
