.lockadmin {
  fill: #80caf0;
  fill-opacity: 0.6 !important;
}

.selected {
  fill: #5cfa00 !important;
  fill-opacity: 0.6 !important;
}

.booked_929497 {
  fill: #929497;
  fill-opacity: 0.6 !important;
}

.booked_FBED21 {
  fill: #fbed21;
  fill-opacity: 0.6 !important;
}

.booked_FF8D8D {
  fill: #ff8d8d;
  fill-opacity: 0.6 !important;
}
.booked_F7E305 {
  fill: #f7e305;
  fill-opacity: 0.6 !important;
}

.booked_FF0000 {
  fill: #ff0000;
  fill-opacity: 0.6 !important;
}
#transparent_circle circle {
  fill-opacity: 0.1;
  cursor: pointer;
}
#svgImage {
  background-color: white;
}
.long-paragraph > p {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ck-balloon-panel {
  z-index: 10000000;
}

#svgImage {
  padding-top: 100px;
}

@media screen and (max-width: 991px) {
  #svgImage {
    padding-top: 160px;
  }
}

@media screen and (max-width: 480px) {
  #svgImage {
    padding-top: 150px;
  }
}

@media screen and (max-width: 359px) {
  #svgImage {
    padding-top: 0;
  }
}
